.doughnut_row {
  flex: 1;
  background-color: #1c1b24;
  border-radius: 20px;
  padding: 25px 23px;
}
.doughtnut_container {
  max-width: 100%;
  margin-bottom: 36px;
}
.doughnut_row_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.doughnut_icon_container {
  border: 0.5px solid #333;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  border-radius: 3px;
}
.doughnut_icon {
  font-size: 15px;
}
.doughnut_icon_container:hover {
  background-color: #35343d;
}

.doughnut_details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  font-size: 12px;
  color: #fff;
}
.direct_dot {
  background-color: #cff4a5;
}
.Paid_dot {
  background-color: #fcbafc;
}
.Social_dot {
  background-color: #e4dafe;
}
.other_dot {
  background-color: #8963ff;
}
.doughnut_infodetail {
  display: flex;
  align-items: center;
  gap: 10px;
}
